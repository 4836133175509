import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { unstable_serialize as unstableSerialize } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { fetchCategories } from 'api/seo';
import { useUiStore } from 'stores/ui';
import * as uiSelectors from 'stores/ui/selectors';
import { buildCategoryMenuItem } from 'utils/header';

interface Props {
  type: 'column' | 'row';
  className?: string;
}

const CategoriesInternalLinks: FC<Props> = ({ className, type }) => {
  const { t } = useTranslation();
  const activeSegment = useUiStore(uiSelectors.activeSegment);

  const { data: categories } = useSWRImmutable(
    activeSegment ? unstableSerialize(['/v1/seo/categories', activeSegment]) : null,
    () => fetchCategories(activeSegment),
  );

  if (!activeSegment || !categories?.length) {
    return null;
  }

  const categoryList = buildCategoryMenuItem(activeSegment, categories);

  return (
    <div
      className={clsx('flex w-full text-grey-80', className, {
        'flex-col': type === 'column',
        'flex-row': type === 'row',
      })}
    >
      <h2>
        {t('Categories')}
        {type === 'row' ? ':' : ''}{' '}
      </h2>
      <ul
        className={clsx('inline-flex flex-wrap gap-y-3', {
          'mt-3': type === 'column',
        })}
      >
        {categoryList.map((category, id) => (
          <li
            key={category.id}
            className={clsx('px-3 underline', {
              '-ml-3': type === 'column' && id !== 0,
              'border-l border-solid border-slate-900': id !== 0,
            })}
          >
            <a href={category.url}>{category.label}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoriesInternalLinks;
